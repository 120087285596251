;( function(){
	$('.row-accordion').each( function(){
		const accordion = $(this);
		const blocks = $(accordion.find( '.block'));
		blocks.each( function( block ){
			$(this).on('click', function(){
				if( $(this).hasClass( 'active')){
					$(this).removeClass( 'active');
				}else{
					blocks.removeClass( 'active');
					$(this).addClass( 'active');
				}
			})
		})
	});
})();