// look for the Website app ( directive ) 
// initialize angular if found
if($('[ng-app="WebsiteApp"]').length > 0 || $('[data-ng-app="WebsiteApp"]').length > 0){
	
	var ng_app = angular.module('WebsiteApp', [
		'app.controllers', 'app.page_controllers','app.directives', 
		'app.services', 'app.filters', 'mx.directives', 'mx.filters', 'mx.components' , 'mx.services'
	])
	.config(['$httpProvider', function($httpProvider) {
			$httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
			$httpProvider.defaults.headers.common["X-Requested-By-Angular"] = 'Angular';
	}])
	.run(function(){});
	
	
	/** ############ @
			Controllers
			############ **/
	
	angular.module('app.page_controllers', []);
	angular.module('app.controllers', []);
	angular.module('app.components', []);
	angular.module('app.directives', []);
	angular.module('app.services', []);
	angular.module('app.filters', []);

}

