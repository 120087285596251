// jQuery(document).ready(function($) {
//
// ( function(){
// 	// document.body.addEventListener( 'click', e => {
// 	// 	console.log( e.target )
// 	// })
// 	const app = {
// 		init: () => {
// 			app.triggers = [];
// 			app.menus = document.querySelectorAll( '.mega-menu');
// 			app.menus.forEach( menu => {
// 				app.addMegaMenu( menu );
// 			})
// 			document.addEventListener( 'sidebar.open' , app.closeMenus );
// 			document.addEventListener( 'navbar.close' , app.closeMenus );
// 			document.addEventListener( 'navbar.open' , app.closeMenus );
// 			console.log('mm init');
// 		},
// 		addMegaMenu : ( menu ) => {
// 			const id = menu.id;
// 			const triggers = document.querySelectorAll( '[href="#mega-menu--' + id + '"]' )
// 			const closeBtns = document.querySelectorAll( '[data-mega-menu-close]' )
// 			if( triggers.length > 0 ) {
// 				triggers.forEach( trigger => {
// 					trigger.dataset.menu = id;
// 					trigger.addEventListener( 'click' , e => app.doMenuAction( e ) )
// 					app.triggers.push( trigger )
// 				} );
// 			}
// 			if( closeBtns.length > 0 ) {
// 				closeBtns.forEach( closeBtn => {
// 					closeBtn.addEventListener( 'click' , e => app.closeMenus() );
// 				});
// 			}
// 		},
// 		closeMenus: () => {
// 			app.menus.forEach( menu => { menu.classList.remove( 'active'); });
// 			app.triggers.forEach( trigger => { trigger.classList.remove( 'active'); });
// 			document.body.classList.remove('mega-menu--open')
// 		},
// 		doMenuAction: ( e) =>  {
// 			const trigger = e.target;
// 			const activeMenu =document.querySelector( '.mega-menu.active');
// 			const menu = document.getElementById( trigger.dataset.menu );
// 			let doOpen = false;
// 			app.closeMenus();
// 			if( menu !== null ) {
// 				doOpen = true;
// 				if( activeMenu !== null  && activeMenu.id == trigger.dataset.menu ){
// 					doOpen = false;
// 				}
// 			}
// 			if( doOpen ) {
// 				menu.classList.add( 'active');
// 				document.body.classList.add('mega-menu--open')
// 				trigger.classList.add( 'active');
// 				document.dispatchEvent(new CustomEvent('mega-menu.open' , { detail : { 'menu' : menu.id } } ) );
// 			}else{
// 				document.body.classList.remove('mega-menu--open')
// 			}
// 		}
// 	}
// 	app.init();
// 	console.log('mm fire');
//
// }());
// console.log('doc ready');
//
// });
