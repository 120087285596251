// Add passive listeners for Performance Audit
// document.addEventListener('touchstart', onTouchStart, {passive: true});



jQuery(document).ready(function($) {


  /* !----------------- SCROLL MAGIC ------------------! */



		window.onload = function() {
			var sections = $('section');
			console.log(sections);
			ScrollTrigger.create({
				trigger: sections[1],
				start: "top center",
				end: "bottom -200%",
				toggleClass: {targets: '#mouse-follow-object', className: 'pos-2'}
			});
			ScrollTrigger.create({
				trigger: sections[3],
				start: "top top",
				end: "bottom -200%",
				toggleClass: {targets: '#mouse-follow-object', className: 'pos-3'}
			});
			ScrollTrigger.create({
				trigger: sections[6],
				start: "top top",
				end: "bottom -200%",
				toggleClass: {targets: '#mouse-follow-object', className: 'pos-4'}
			});


			let slideIn = gsap.timeline({
			})
			.to('.display-1', {
				translateY: "0",
				opacity: 1,
				duration: .5,
			})
			// .to('.display-2', {
			// 	translateX: "0",
			// 	opacity: 1,
			// 	duration: .5,
			// })
			.to('.content', {
				translateY: "0",
				opacity: 1,
				duration: .5,
			})
			.to('img', {
				translateY: "0",
				opacity: 1,
				duration: .5,
			})


			ScrollTrigger.create({
				trigger: '.row-hero-feature',
				start: "top bottom",
				onEnter: () => slideIn.play()
			})

			let delay = parseInt(.5);

			//Single column animation
			gsap.to('.row-single-column .eyebrow', {
				scrollTrigger: {
					trigger: '.row-single-column',
					start: 'top center'
				},
				translateY: "0",
				opacity: 1,
				duration: .5
			})
			gsap.to('.row-single-column .display-2', {
				scrollTrigger: {
					trigger: '.row-single-column',
					start: 'top center'
				},
				translateY: "0",
				opacity: 1,
				duration: .5
			})
			gsap.to('.row-single-column .content-block', {
				scrollTrigger: {
					trigger: '.row-single-column',
					start: 'top center'
				},
				delay: delay,
				translateY: "0",
				opacity: 1,
				duration: .75
			})
		}

		//Card row animation
		// gsap.to('.row-card-row .eyebrow', {
		// 	scrollTrigger: {
		// 		trigger: '.row-card-row',
		// 		start: 'top center'
		// 	},
		// 	translateY: "0",
		// 	opacity: 1,
		// 	duration: .5
		// })
		// gsap.to('.row-card-row .display-2', {
		// 	scrollTrigger: {
		// 		trigger: '.row-card-row',
		// 		start: 'top center'
		// 	},
		// 	translateY: "0",
		// 	opacity: 1,
		// 	duration: .75
		// })

	/**
	 * Navbar Mobile
	**/
	$('.navbar').on('show.bs.collapse', function () {
		$('body').addClass('navbar-open');
		document.dispatchEvent(new CustomEvent('navbar.open' ) );
		closeAboutSidebar();
	})
	$('.navbar').on('hide.bs.collapse', function () {
		$('body').removeClass('navbar-open');
		document.dispatchEvent(new CustomEvent('navbar.close' ) );
		closeAboutSidebar();
	})

	/**
	 * About Menu Sidebar
	**/
	const closeAboutSidebar = function(){
		$('.about-menu-sidebar').removeClass( 'open');
		$('body').removeClass( '.sidebar-right--open');
		$('.menu--child').removeClass( 'open')
	}
	document.addEventListener( 'mega-menu.open' , e => {
		closeAboutSidebar();
	});
	$('.about-menu-sidebar .btn-close').on( 'click', function(){
		$('.about-menu-sidebar').removeClass( 'open');
		$('body').removeClass( '.sidebar-right--open');
		$('.menu--child').removeClass( 'open')
	})
	$('.about-menu-toggle').on( 'click', function(){
		$('.about-menu-sidebar').toggleClass( 'open');
		if( $('.about-menu-sidebar').hasClass( 'open') ){
			$('body').addClass( '.sidebar-right--open');
			document.dispatchEvent(new CustomEvent('sidebar.open' , { detail : { 'menu' : 'sidbar.right' } } ) );
		}else{
			$('body').removeClass( '.sidebar-right--open');
		}
	})
	$('.about-menu-sidebar .menu-item.has-children a').on('click' , function(){
		const target = $($(this).data('target'));
		target.addClass( 'open');
	})
	$('.menu--child .btn-back').on( 'click' , function(){
		$('.menu--child').removeClass( 'open')
	})


});


/**
 * Foloow Mouse
 */

// ;( function(){
//
//
// 	function findRectangularQuadrant(x, y, width, height) {
// 		var vertical;
// 		if(y < height / 2) { vertical = "top"; }
// 		else { vertical = "bottom"; }
// 		if(x < width / 2) { horizontal = "left"; }
// 		else { horizontal = "right"; }
// 		return vertical + horizontal;
// 	}
//
//
// 		var followCursor = (
// 			function() {
// 				var object = document.getElementById('mouse-follow-object');
// 				object.classList.add( 'active')
// 				// window.setTimeout( () => {
// 				// 	s.classList.add( 'red');
// 				// } , 4000 )
//
//
// 				return {
// 				// init: function() {
// 				// 	document.body.appendChild(s);
// 				// },
//
// 				run: function(e) {
//
// 					const x = e.clientX;
// 					const y = e.clientY;
//
// 					// const left = `calc(100vw - ${x/2}px)`;
// 					// const top = `calc(100vh - ${y/2}px)`;
// 					const left = window.outerWidth - ( x / 2 ) + 'px';
// 					const top = window.outerHeight - ( y / 2 ) + 'px';
//
// 					object.style.left = left;
// 					object.style.top = top;
// 				}
// 			};
// 			}()
// 		);
//
// 		window.onload = function() {
// 			// followCursor.init();
// 			// document.body.onmousemove = followCursor.run;
// 		}
//
//
// })();




;( function(){
	/* !----------------- IMAGE GRID ------------------! */
	// Modified and amended to handle video in modals 2/25/21
	var reset_iframes = function( $el ){
		var frames = $($el.find( 'iframe' ));
		$(frames).each( function( e ){
			var src = $(this).attr('src');
			$(this).attr( 'src' , '' );
			$(this).attr( 'data-src', src );
		});
	}


	// Setting and removing iframe src when video modal is opened/closed
	var set_iframe_src = function($el){
		var frames = $($el.find( 'iframe' ));
		// console.log(frames);
		$(frames).each( function( e ){
			var src = $(this).attr('data-src');
			$(this).attr( 'src', src );
		});
	}

	var rm_iframe_src = function($el){
		var frames = $($el.find( 'iframe' ));
		// console.log(frames);
		$(frames).each( function( e ){
			$(this).attr( 'src', '' );
		});
	}


	$('.modal--video').on('shown.bs.modal', function( e ){
		set_iframe_src($(this));
	});


	$('.modal--video').click(function(){
		console.log('hide');
		rm_iframe_src($(this));
	});

})();


// reverse hover state on cards
;( function(){
	if ($('.mx-card-row')){
		$('.mx-card-row .outer-link').mouseenter(function(){
			$('.mx-card-row .outer-link').css({opacity: 0.7});
			$(this).css({opacity: 1});
		});
		$('.mx-card-row .outer-link').mouseleave(function(){
			$('.mx-card-row .outer-link').css({opacity: 1});
		});
	}
})();

jQuery(document).ready(function($) {

( function(){
	// document.body.addEventListener( 'click', e => {
	// 	console.log( e.target )
	// })
	const app = {
		init: () => {
			app.triggers = [];
			app.menus = document.querySelectorAll( '.mega-menu');
			app.menus.forEach( menu => {
				app.addMegaMenu( menu );
			})
			document.addEventListener( 'sidebar.open' , app.closeMenus );
			document.addEventListener( 'navbar.close' , app.closeMenus );
			document.addEventListener( 'navbar.open' , app.closeMenus );

		},
		addMegaMenu : ( menu ) => {
			const id = menu.id;
			const triggers = document.querySelectorAll( '[href="#mega-menu--' + id + '"]' )
			const closeBtns = document.querySelectorAll( '[data-mega-menu-close]' )
			if( triggers.length > 0 ) {
				triggers.forEach( trigger => {
					trigger.dataset.menu = id;
					trigger.addEventListener( 'click' , e => app.doMenuAction( e ) )
					app.triggers.push( trigger )
				} );
			}
			if( closeBtns.length > 0 ) {
				closeBtns.forEach( closeBtn => {
					closeBtn.addEventListener( 'click' , e => app.closeMenus() );
				});
			}
		},
		closeMenus: () => {
			app.menus.forEach( menu => { menu.classList.remove( 'active'); });
			app.triggers.forEach( trigger => { trigger.classList.remove( 'active'); });
			document.body.classList.remove('mega-menu--open')
		},
		doMenuAction: ( e) =>  {
			const trigger = e.target;
			const activeMenu =document.querySelector( '.mega-menu.active');
			const menu = document.getElementById( trigger.dataset.menu );
			let doOpen = false;
			app.closeMenus();
			if( menu !== null ) {
				doOpen = true;
				if( activeMenu !== null  && activeMenu.id == trigger.dataset.menu ){
					doOpen = false;
				}
			}
			if( doOpen ) {
				menu.classList.add( 'active');
				document.body.classList.add('mega-menu--open')
				trigger.classList.add( 'active');
				document.dispatchEvent(new CustomEvent('mega-menu.open' , { detail : { 'menu' : menu.id } } ) );
			}else{
				document.body.classList.remove('mega-menu--open')
			}
		}
	}
	app.init();


}());


});
