;( function(){
	const videoCarousels = $('.video-carousel');
	if (videoCarousels){
		videoCarousels.each(  function( e ){

			const id = $(this).attr( 'id' )
			var swiperThumbs = new Swiper('#'+id+' .carousel__swiper-thumbs-container', {
				slidesPerView: 5.5,
                spaceBetween: 10,
                freeMode: true,
                breakpoints: {
                    1080: {
                      slidesPerView: 4.5,
                    },
                    768: {
                        slidesPerView: 3.5,
                    }
                },
			});

            var videoSwiper = new Swiper('#'+id+' .carousel__swiper-container', {
                slidesPerView: 1,
                navigation: {
                    nextEl: '.carousel__swiper-button-next-' + id,
                    prevEl: '.carousel__swiper-button-prev-' + id,
                },
                scrollbar: {
                    el: '.swiper-scrollbar-' + id,
                    draggable: true,
                    dragSize: '100px',
                },
                thumbs: {
                    swiper: swiperThumbs,
                    slideThumbActiveClass: 'thumbs-active',
                },
                on: {
                    slideChange: function () {
                        //Stop all of the slides if they are currently playing
                        let swiperSlides = (this.el.querySelectorAll(".swiper-slide"));
                        if (Wistia && Wistia.api) {
                            if (swiperSlides) {
                                for (const slide of swiperSlides) {
                                    if (slide.dataset && slide.dataset.wistia_id) {
                                        let video = Wistia.api(slide.dataset.wistia_id);

                                        if (video) {
                                            video.pause();
                                        }
                                    }
                                }
                            }
                        }

                        let index = this.realIndex;
                        let currentSlide = this.slides[index];

                        if (currentSlide) {

                            //Get the video ID
                            if (Wistia && Wistia.api) {
                                if (currentSlide.dataset && currentSlide.dataset.wistia_id) {
                                    //console.log(Wistia.api(currentSlide.dataset.wistia_id));
                                }
                            }
                        }

                        //Change the thumbs slider attribute
                        if (swiperThumbs.slides && swiperThumbs.slides[index]) {
                            for (let i = 0; i < swiperThumbs.slides.length; i++) {
                                swiperThumbs.slides[i].dataset.thumbsActive = '';
                            }
                            swiperThumbs.slides[videoSwiper.activeIndex].dataset.thumbsActive = 'active';
                        }
                    },
                    init: function() {
                        //Initialize the main iframe
                        let iframe = this.slides[0].querySelector("iframe");
                        if (iframe) {
                            if (iframe.dataset) {
                                if (iframe.dataset.src && !iframe.attributes.src) {
                                    iframe.setAttribute('src', iframe.dataset.src);
                                }
                            }
                        }

                        //Add the active indicator for the thumbs
                        swiperThumbs.slides[0].dataset.thumbsActive = 'active';
                    }
                },
			});
		})

		$('.carousel__swiper-button-next')[0].classList.remove('swiper-button-disabled');
	}
} )();

function handleVideoOverlayButtonClick(id) {
    //Find the container and set the class

    let slides = document.querySelectorAll("[data-wistia_id='"+id+"']");

    slides.forEach((slide) => {
        slide.classList.remove('swiper-slide-overlay-visible');
        slide.classList.add('swiper-slide-overlay-hidden');
    });
    
    if (Wistia && Wistia.api) {
        let video = Wistia.api(id);
        if (video) {
            video.play();
        }
    }
}
