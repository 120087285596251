;( function(){
	const testimonials = $('.testimonial-carousel');
	if (testimonials){
		testimonials.each(  function( e ){
			const id = $(this).attr( 'id' )
			var swiper = new Swiper('.testimonial__swiper-container', {
				slidesPerView: 1,
				navigation: {
				  nextEl: '.testimonial__swiper-button-next-' + id,
				  prevEl: '.testimonial__swiper-button-prev-' + id,
				},
			 });
		})
	}

} )();
