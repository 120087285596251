;( function(){
	const carousels = $('.image-carousel');
	if (carousels){
		carousels.each(  function( e ){
			const id = $(this).attr( 'id' )
			var swiper = new Swiper('.carousel__swiper-container', {
				slidesPerView: 4.5,
				navigation: {
				  nextEl: '.carousel__swiper-button-next-' + id,
				  prevEl: '.carousel__swiper-button-prev-' + id,
				},
				scrollbar: {
					el: '.swiper-scrollbar-' + id,
					draggable: true,
					dragSize: '100px',
				  },
			  });
		})

		$('.carousel__swiper-button-next')[0].classList.remove('swiper-button-disabled');
	}

} )();
